import React from 'react'

const Head = () => {
    return (
        <div className='head'>

            <div className='head2'>
                <p>Shop No. 5 Sai Market Faizabad Road BBD University TiwariGanj  Lucknow  </p>
            </div>


            <div className='head2'>
                <p> dhiarya_mbbs@yahoo.co.in  <b>Timing:</b> Mon.  to Sat. 05pm to 08pm</p>
            </div>


           


        </div>
    )
}

export default Head
import React from 'react'
import Section from '../Components/Section';
import Footer from '../Components/Footer';

const About = () => {
  return (
    <>
    <Section/>
    <Footer/>
    
    </>
  )
}

export default About
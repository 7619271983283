import React from 'react'
import Footer from '../Components/Footer'

const Failerkidney = () => {
    return (
        <>
            <br />
            <center><h1> Diabetic Kidney Diseases. </h1></center>
            <div className='cronic'>
                <p>Over time, diabetes that isn't well controlled can damage blood vessels in the kidneys that filter waste from the blood. This can lead to kidney damage and cause high blood pressure. High blood pressure can cause more kidney damage by raising the pressure in the filtering system of the kidneys.</p>
            </div>
            <Footer />
        </>
    )
}

export default Failerkidney
import React from 'react'
import Footer from '../Components/Footer'

const Cronic = () => {
    return (
        <>
            <br />
            <center><h1>Diabetes Hypertension.</h1></center>
            <div className='cronic'>
                <p>Diabetes causes damage by scarring the kidneys, which in turn leads to salt and water retention, which in turn raises blood pressure. Over time,
                     diabetes damages the small blood vessels, causing the 
                    walls of the blood vessels to stiffen and function improperly.
                     These changes contribute to high blood pressure.”</p>
            </div>
            <Footer />
        </>
    )
}

export default Cronic
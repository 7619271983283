import React from 'react'
import Footer from '../Components/Footer'

const Hemolytic = () => {
    return (
        <>
            <br />
            <center><h1>ADPKD – Autosomal Dominant Polycystic Kideny Diseases.</h1></center>
            <div className='cronic'>
                <p>Autosomal dominant polycystic kidney disease (ADPKD) is the most common genetic cause of renal failure worldwide. ADPKD is a multisystem and progressive disease with cyst formation, kidney enlargement, and extrarenal organ involvement (eg, liver, pancreas, spleen, and arachnoid membranes).
                </p>
            </div>
            <Footer />
        </>
    )
}

export default Hemolytic
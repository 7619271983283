import React from 'react'
import Header from './Header'
import Section from './Section';
import Derivation from './Derivation';
import Depart from './Depart';
import Footer from '../Components/Footer'
import Slider from './Slider';

const Layout = () => {
  return (
    <>
      <div className='main'>
        <Header />
        <Section />
        <Derivation />
        <Depart />
        <br/>
        <Slider/>
        <Footer />
      </div>


    </>
  )
}

export default Layout
import React, { useState, useEffect } from 'react';


import slide1 from '../Img/banner3.png';
import slide2 from '../Img/banner2.png';
import slide3 from '../Img/banner1.png';

const Header = () => {
  const slides = [slide1, slide2, slide3];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [currentSlide, slides.length]);

  return (
    <div className='header'>
      <div className='slider'>
        <img src={slides[currentSlide]} alt={`Slide ${currentSlide + 1}`} />
      </div>
    </div>
  );
};

export default Header;

import React from 'react'
import Footer from '../Components/Footer'

const Blood = () => {
    return (
        <>
            <br />
            <center><h1>Hypertensive Kidney Diseases. </h1></center>
            <div className='cronic'>
                <p>Renal hypertension (or renovascular hypertension) is high blood pressure caused by 
                    the narrowing of your arteries that carry blood to your kidneys. It is also sometimes called renal artery stenosis. Because your kidneys are not getting enough blood, they react by making a hormone that makes your
                     blood pressure rise.</p>
            </div>
            <Footer />
        </>
    )
}

export default Blood
import React from 'react'
import Footer from '../Components/Footer'

const Polystic = () => {
    return (
        <>
            <br />
            <center><h1>UTI – Urinary Tract Infection.</h1></center>
            <div className='cronic'>
                <p>A UTI develops when microbes enter the urinary tract and cause infection. Bacteria are the most common cause of UTIs,
                     although fungi rarely can also infect the urinary tract. E. coli bacteria, which live in the bowel, cause most UTIs.
                </p>
            </div>
            <Footer />
        </>
    )
}

export default Polystic
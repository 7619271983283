import React from 'react';
import Footer from '../Components/Footer';


import gal1 from '../Img/gal1.jpg';
import gal2 from '../Img/gal2.jpg';
import gal3 from '../Img/gal3.jpg';
import gal4 from '../Img/gal4.jpg';
import gal5 from '../Img/gal5.jpg';
import gal6 from '../Img/gal6.jpg';
import gal7 from '../Img/gal7.jpg';
import gal8 from '../Img/gal8.jpg';
import gal9 from '../Img/gal9.jpg';
import gal10 from '../Img/gal10.jpg';
import gal11 from '../Img/gal11.jpg';
import gal12 from '../Img/gal12.jpg';

import gal13 from '../Img/gal13.jpg';
import gal14 from '../Img/gal18.jpg';
import gal15 from '../Img/gal9.jpg';
import gal16 from '../Img/gal20.jpg';
import gal17 from '../Img/gal21.jpg';
import gal18 from '../Img/gal22.jpg';
import gal19 from '../Img/gal23.jpg';
import gal20 from '../Img/gal24.jpg';
import gal21 from '../Img/gal25.jpg';


import vid1  from '../video/new (1).mp4';
import vid2  from '../video/new (2).mp4';
import vid3  from '../video/new (3).mp4';





const Gallery = () => {


  return (
    <>
      <br/><br/>

      <center><h1>GALLERY</h1></center>
      <div className='gallery'>
        <img src={gal1} />
        <img src={gal2} />
        <img src={gal3} />
        <img src={gal4} />
      </div>


      <div className='gallery'>
        <img src={gal5} />
        <img src={gal6} />
        <img src={gal7} />
        <img src={gal8} />
      </div>

      <div className='gallery'>
        <img src={gal9} />
        <img src={gal10} />
        <img src={gal11} />
        <img src={gal12} />
      </div>



      <div className='gallery'>
        <img src={gal13} />
        <img src={gal14} />
        <img src={gal15} />
        <img src={gal16} />
      </div>

      <div className='gallery'>
        <img src={gal17} />
        <img src={gal18} />
        <img src={gal19} />
        <img src={gal20} />
      </div>


      <div className='gallery'>
        <img src={gal21} />
        <video width="250" height="300" controls>
          <source src={vid1} type="video/mp4" />
        </video>
        
        
        <video width="250" height="300" controls>
          <source src={vid2} type="video/mp4" />
        </video>

        <video width="250" height="300" controls>
          <source src={vid3} type="video/mp4" />
        </video>
      </div>


      <Footer />
    </>
  );
};

export default Gallery;

import React from 'react'
import Footer from '../Components/Footer'

const Nephrotic = () => {
    return (
        <>
            <br />
            <center><h1>Nephrotic Syndrome</h1></center>
            <div className='cronic'>
                <p>Nephrotic syndrome is often caused by damage to small blood vessels in the kidneys that filter waste and excess water from the blood. An underlying health condition usually plays a role.
                    Symptoms include swelling around the eyes and in the feet and ankles, foamy urine and weight gain due to excess fluid retention.
                    Treatment addresses underlying conditions and might include blood pressure medication, diuretics, steroids, and other immunosuppressive drugs.
                </p>
            </div>
            <Footer />
        </>
    )
}

export default Nephrotic
import React from 'react'

const Derivation = () => {
  return (
    <div className='derivate'>

      <div className="icon-bar">
        <a href="https://www.facebook.com/p/Drdhairya-Prakash-prajapati-100069269232940/" class="facebook"><i class="fa fa-facebook"></i></a>
        <a href="#" class="twitter"><i class="fa fa-instagram"></i></a>
        <a href="https://www.youtube.com/watch?v=bYwBGUphfiM" class="youtube"><i class="fa fa-youtube-play"></i></a>
      </div>

      <h1>Elevating the quality of life</h1>
      <p>Ensuring comfort, care and safety at every step of your dialysis journey.</p>

      <a href=''><button>Book Appointment</button></a>


      <div className="icon-bar2">
        <a href="https://wa.me/7982664875" class="whatsapp">
          <i class="fa fa-whatsapp"></i>
        </a>


        <a href="Mailto:dhiarya_mbbs@yahoo.co.in" class="twitter2"><i class="fa fa-envelope"></i></a>
        <a href="Tel:+91 7982664875" class="youtube2"><i class="fa fa-phone"></i></a>
      </div>
    </div>
  )
}

export default Derivation
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Backs from '../Img/backs.jpg'; // Import the backs image

import doctor from '../Img/dr.dayal2.jpg';
import post1 from '../Img/poster1.jpg';
import post2 from '../Img/poster2.jpg';
import post3 from '../Img/poster3.jpg';
import post4 from '../Img/poster4.jpg';

const section = () => {
    return (
        <>

            <div className="divider">KIDENY</div>
            <div className="divider">CARE</div>
            <div className="divider">CENTER</div>


            <div className='sect'>
                <div className='sect1'>
                    <Card className='profile'>
                        <Card.Img variant="top" src={doctor} />
                        <Card.Body>
                            <Card.Title>Dr. Dhairya Prakash Prajapati</Card.Title>
                            <Card.Text>
                                M.B.B.S , M.D , DNB , Nephrology.<br />
                                DNB – Nephrology from Max Hospital , Saket , New Delhi . <br />
                                Member of ISN ( Indian Society of Nephrology ).<br />
                                Member of ISOT ( Indian Society of Organ Transplant ). <br />
                                Dr. Dhairya has special interest in live and cadaveric renal transplant.
                            </Card.Text>
                            <Button variant="primary">Contact Us </Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className='sect2'>


                    <h1>About Us </h1>
                    <p>Dr. Dhairya Prakash Prajapati  is a highly skilled and
                        experienced Nephrologist. With over over a decade of experience,
                        Dr. Dhairya Prakash Prajapati is known for his exceptional
                        medical expertise and compassionate patient care. He has dedicated his career to diagnosing and treating a wide range of medical conditions while providing personalized healthcare services to his patients.</p>

                    <p>Dr. Dhairya Prakash Prajapati is known for his compassionate and personalized approach to patient care. He takes the time to listen to his patients' concerns and works closely with them to develop a treatment plan that meets their individual needs.</p>

                    <p>He Completed his M.B.B.S from Government Medical College , Surat , Gujrat in 2008 and M.D from Nalanda Medical College , Patna , Bihar in 2015 .</p>








                </div>
            </div>

        </>
    );
};

export default section;

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom'; // Import Link

import logo from '../Img/logo.png';
import logo2 from '../Img/logo2.jpeg'

function BasicExample() {
  return (

    <div className='navigation'>
      <Navbar expand="lg" >
        <Container>

          <Navbar.Brand as={Link} to="/">
            <img src={logo2} alt="Logo" />
          </Navbar.Brand>


          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>

              <NavDropdown title="About" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/About">About Hospital</NavDropdown.Item>

              </NavDropdown>

              <NavDropdown title="Treatments" id="basic-nav-dropdown">

                <NavDropdown.Item as={Link} to="/Cronic">Diabetes Hypertension.</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Failerkidney">Diabetic Kidney Diseases.</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Blood">Hypertensive Kidney Diseases.</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Nephrotic">Nephrotic syndrome</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Polystic">UTI – Urinary Tract Infection.</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Kidneyston">Kidney Transplant – Live , Cadaveric.</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Hemolytic">ADPKD </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Renal"> Renal Stone.</NavDropdown.Item>

                <NavDropdown.Item as={Link} to=""> AKI – Acute Kidney Injury..</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="">CKD – Chronic Kidney Diseases</NavDropdown.Item>
                <NavDropdown.Item as={Link} to=""> Parental Dialysis.</NavDropdown.Item>


              </NavDropdown>

              <Nav.Link as={Link} to="/Gallery">Gallery</Nav.Link>

              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>

              <Link to='/Appointment' style={{ textDecoration: 'none' }}>
                <div className='appoint'>
                  <Nav.Link href="Appointment" style={{ color: 'white' }}>Appointment</Nav.Link>
                </div>
              </Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default BasicExample;

import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Components/Layout';
import Navbar from './Components/Navbar';
import About from './Pages/About';
import Contact from './Pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from './Components/Head';
import Gallery from './Pages/Gallery';
import Appointment from './Pages/Appointment'

import Cronic from './Pages/Cronic'
import Failerkidney from './Pages/Failerkidney';
import Blood from './Pages/Blood';
import Nephrotic from './Pages/Nephrotic';
import Polystic from  './Pages/Polystic';
import Kidneyston from './Pages/Kidneyston';
import Hemolytic from  './Pages/Hemolytic';
import Renal from './Pages/Renal';


function App() {
  return (
    <div className='main'>
      <BrowserRouter basename=''>
        <Head />
        <Navbar />
        <Routes>
          <Route path="*" element={<Layout />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Appointment" element={<Appointment />} />


          <Route path="/Cronic" element={<Cronic />} />
          <Route path="/Failerkidney" element={<Failerkidney />} />
          <Route path="/Blood" element={<Blood />} />
          <Route path="/Nephrotic" element={<Nephrotic />} />
          <Route path="/Polystic" element={<Polystic />} />
          <Route path="/Kidneyston" element={<Kidneyston />} />
          <Route path="/Hemolytic" element={<Hemolytic />} />
          <Route path="/Renal" element={<Renal />} />




        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Slide1 from '../Img/slide1.jpg';
import Slide2 from '../Img/slide2.jpg';
import Slide3 from '../Img/slide3.jpg';
import Slide4 from '../Img/slide4.jpg';
import Slide5 from '../Img/slide5.jpg';
import Slide6 from '../Img/slide6.jpg';

const Slider = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const slides = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6];

  return (
    <Carousel responsive={responsive}>
      {slides.map((slide, index) => (
        <div key={index}>
          <img src={slide} alt={`Slide ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
        </div>
      ))}
    </Carousel>
  );
};

export default Slider;

import React from 'react'
import endro from '../Img/endro.jpg'
import endro1 from '../Img/lapro.jpg'
import endro2 from '../Img/proted.jpg'
import endro3 from '../Img/female.jpg'

import endro4 from '../Img/polistic.jpg'
import endro5 from '../Img/kidni.jpg'
import endro6 from '../Img/hemultic.jpg'
import endro7 from '../Img/renal.jpg'


import Actusk from '../Img/actual.jpg'
import Parent from '../Img/parental.jpg'

const Depart = () => {
    return (
        <>
            <center><h1>Treatments</h1></center>
            <div className='depart'>
                <div className='depart2'>
                    <h1>  Diabetes Hypertension.</h1>
                    <img src={endro} />
                   
                </div>
                <div className='depart2'>
                    <h1> Diabetic Kidney Diseases.</h1>
                    <img src={endro1} />
                   
                </div>
                <div className='depart2'>
                    <h1> Hypertensive Kidney Diseases.</h1>
                    <img src={endro2} />
                    
                </div>
                <div className='depart2'>
                    <h1>Nephrotic syndrome</h1>
                    <img src={endro3} />
                   
                </div>
            </div>

            <div className='depart'>
                <div className='depart2'>
                    <h1>  UTI – Urinary Tract Infection.</h1>
                    <img src={endro4} />
                    
                </div>
                <div className='depart2'>
                    <h1> Kidney Transplant – Live , Cadaveric.</h1>
                    <img src={endro5} />
                   
                </div>
                <div className='depart2'>
                    <h1>  ADPKD – Autosomal Dominant Polycystic Kideny Diseases.</h1>
                    <img src={endro6} />
                    
                </div>
                <div className='depart2'>
                    <h1>Renal  stones</h1>
                    <img src={endro7} />
                    
                </div>
            </div>




            <div className='depart'>
                <div className='depart2'>
                    <h1>AKI – Acute Kidney Injury.</h1>
                    <img src={Actusk} />
                    
                </div>
                <div className='depart2'>
                    <h1> CKD – Chronic Kidney Diseases.</h1>
                    <img src={endro5} />
                  
                </div>
                <div className='depart2'>
                    <h1>  Parental Dialysis.</h1>
                    <img src={Parent} />
                   
                </div>
              
            </div>

        </>
    )
}

export default Depart
import React from 'react'
import Footer from '../Components/Footer'

const Kidneyston = () => {
    return (
        <>
            <br />
            <center><h1>Kidney Transplant – Live , Cadaveric.</h1></center>
            <div className='cronic'>
                <p>The average half-life of transplanted cadaver kidneys is 6–7 years, with tissue destruction
                     being due in many cases to the nephrotoxicity of the immunosuppressive agents, such as tacrolimus. The 10 year success rate with transplanted livers is higher than heart which 
                    is ranked higher than kidneys
                </p>
            </div>
            <Footer />
        </>
    )
}

export default Kidneyston
import React, { useState } from 'react';
import Footer from '../Components/Footer';

const Appointment = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    date: '',
    time: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your appointment submission logic here
    console.log('Appointment submitted:', formData);
    // Reset form fields
    setFormData({
      name: '',
      email: '',
      mobile: '',
      date: '',
      time: ''
    });
    
    // Construct WhatsApp message
    const message = `Name: ${formData.name}Email: ${formData.email}Mobile Number: ${formData.mobile}Date: ${formData.date}Time: ${formData.time}`;
    
    // Open WhatsApp with pre-filled message
    window.open(`https://wa.me/917982664875?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <>
      <div className='contact'>
        <h2>Make an Appointment</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="mobile">Mobile Number:</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />

          <label htmlFor="date">Date:</label>
          <input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />

          <label htmlFor="time">Time:</label>
          <input
            type="time"
            id="time"
            name="time"
            value={formData.time}
            onChange={handleChange}
            required
          />

          <button type="submit">Submit</button>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default Appointment;

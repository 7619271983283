import React from 'react'
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>

            <div className='social'>
                <div className='socil2'>
                    <h1>Dr.  Dhairya Prakash Prajapati</h1>
                </div>
                <div className='socil2'>
                    <a href=''><i class="fa fa-facebook"></i></a>
                    <a href=''><i class="fa fa-instagram"></i></a>
                    <a href=''><i class="fa fa-twitter"></i></a>
                    <a href=''><i class="fa fa-linkedin"></i></a>
                    <a href=''><i class="fa fa-whatsapp"></i></a>
                </div>
            </div>


            <div className='footer'>

                <div className='foot'>
                    <h1>KIDNEY CARE CENTER</h1>
                    <p>Dr. Dhairya Prakash Prajapati is a highly skilled and experienced Nephrologist. With over over a decade of experience, Dr. Dhairya Prakash Prajapati is known for his exceptional medical expertise and compassionate patient care. He has dedicated his career to diagnosing and treating a wide range of medical conditions while providing personalized healthcare services to his patients. </p>
                </div>

                <div className='foot'>
                    <h1>TREATMENTS</h1>
                    <br />
                    <Link to='/Cronic' className='link'>Diabetes Hypertension.</Link><br />
                    <Link to='/Failerkidney' className='link'>Diabetic Kidney Diseases.</Link><br />
                    <Link to='/Blood' className='link'>Hypertensive Kidney Diseases.</Link><br />
                    <Link to='/Nephrotic' className='link'>Nephrotic syndrome</Link><br />
                    <Link to='/Polystic' className='link'>UTI – Urinary Tract Infection.</Link><br />
                    <Link to='/Kidneyston' className='link'>Kidney Transplant </Link><br />
                    <Link to='/Hemolytic' className='link'>ADPKD </Link><br />
                    <Link to='/Renal' className='link'> Renal Stone.</Link>
                </div>




                <div className='foot'>
                    <h1>USEFUL LINK </h1><br />
                    <Link to='/' className='link'>Home</Link><br />
                    <Link to='/About' className='link'>About</Link><br />
                    <Link to='/Appointment' className='link'>Appointment</Link><br />
                    <Link to='/Gallery' className='link'>Gallery</Link><br />
                    <Link to='/Contact' className='link'>Contact</Link><br />
                </div>
                <div className='foot'>
                    <h1>REACH US </h1>
                    <p> <i class="fa fa-map-marker"></i> Shop No. 5 Sai Market Faizabad Road BBD University TiwariGanj Lucknow</p>
                    <p> <i class="fa fa-envelope"></i> dhiarya_mbbs@yahoo.co.in</p>
                    <p> <i class="fa fa-clock-o"></i> Timing: Mon. to Sat. 05pm to 08pm</p>
                    <p> <i class="fa fa-phone"></i> +91 7982664875</p>

                </div>

            </div>

            <p>© 2024 Copyright Kidney  Care Center: Reserved Developed Designed ByCreative Digital World</p>


        </>
    )
}

export default Footer